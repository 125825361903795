<template>
  <RevContainer>
    <RevBreadcrumb
      v-if="priceGuide"
      :ariaLabel="i18n(translations.breadcrumb)"
      :breads
      class="mb-24"
    />
    <h1 class="heading-1">
      {{ title }}
    </h1>
    <p class="text-static-default-low body-2 mt-4">
      {{ subtitle }}
    </p>

    <div v-if="highlight || fallbackProductPageLink" class="mt-56">
      <h2 class="heading-2">
        {{ i18n(translations.highlightTitle, { productModel }) }}
      </h2>
      <p class="text-static-default-low body-2 mb-24 mt-4">
        {{ i18n(translations.highlightSubtitle) }}
      </p>

      <Highlight v-if="highlight && productModel" :highlight :product-model />

      <OutOfStockProductExists
        v-if="
          isBestOffersTableOutOfStock && fallbackProductPageLink && productModel
        "
        :fallback-product-page-link
        :product-model
      />
    </div>

    <div v-if="bestOffersTable" class="mt-56">
      <h2 class="heading-2">
        {{ i18n(translations.bestOffersTitle, { productModel }) }}
      </h2>
      <p class="text-static-default-low body-2 mt-4">
        {{ i18n(translations.bestOffersSubtitle) }}
      </p>

      <BestOffersTable v-bind="bestOffersTable" class="mt-24" />

      <WhatsDifferenceGrades
        v-if="!isBestOffersTableOutOfStock"
        class="mt-24"
      />
    </div>

    <WhyRefurbished v-if="bestOffersTable" class="my-56" />

    <OutOfStockProductDoesNotExist
      v-if="!bestOffersTable && productModel"
      class="my-56"
      :product-model
    />
  </RevContainer>
</template>

<script setup lang="ts">
import { createError, useRuntimeConfig } from '#imports'
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { capitalize } from '@backmarket/utils/string/capitalize'
import { RevBreadcrumb } from '@ds/components/Breadcrumb'
import { RevContainer } from '@ds/components/Container'

import { useRouteLocationWithLocale } from '../../../composables/useRouteLocationWithLocale'
import { HOME } from '../../home/route-names'
import BestOffersTable from '../components/BestOffersTable/BestOffersTable.vue'
import Highlight from '../components/Highlight/Highlight.vue'
import OutOfStockProductDoesNotExist from '../components/OutOfStockProductDoesNotExist/OutOfStockProductDoesNotExist.vue'
import OutOfStockProductExists from '../components/OutOfStockProductExists/OutOfStockProductExists.vue'
import WhatsDifferenceGrades from '../components/WhatsDifferenceGrades/WhatsDifferenceGrades.vue'
import WhyRefurbished from '../components/WhyRefurbished/WhyRefurbished.vue'
import { priceGuidesHubLinkDefinition } from '../constants'

import translations from './PriceGuideProductModel.translations'
import { useGetPriceGuide } from './composables/useGetPriceGuide'
import { useHandleError } from './composables/useHandleError'
import { useHead } from './composables/useHead'

const i18n = useI18n()
const country = useMarketplace().market.countryCode
const resolveLocalizedRoute = useRouteLocationWithLocale()
const { KILL_PRICE_GUIDE_COUNTRIES } = useRuntimeConfig().public

if (KILL_PRICE_GUIDE_COUNTRIES.includes(country)) {
  throw createError({
    statusCode: 404,
    statusMessage: 'Not found',
    fatal: true,
  })
}

const { data: priceGuide, error } = await useGetPriceGuide()

useHandleError(error)

const productModel = computed(() => priceGuide.value?.productModel)
const bestOffersTable = computed(() => priceGuide.value?.bestOffersTable)
const highlight = computed(() => priceGuide.value?.highlight)
const fallbackProductPageLink = computed(
  () => priceGuide.value?.fallbackProductPageLink,
)

const date = new Date()
const dateMonthLongCapitalized = capitalize(i18n.date(date, { month: 'long' }))
const dateYearNumeric = i18n.date(date, { year: 'numeric' })

const startingPrice = computed(() => priceGuide.value?.highlight?.price || null)

const title = computed(() =>
  i18n(translations.title, {
    productModel: productModel.value,
    dateMonthLongCapitalized,
    dateYearNumeric,
  }),
)
const subtitle = computed(() =>
  i18n(translations.subtitle, {
    dateMonthLongCapitalized,
    dateYearNumeric,
    productModel: productModel.value,
    startingPrice: startingPrice.value ? i18n.price(startingPrice.value) : '',
  }),
)

const breads = computed(() => [
  {
    link: resolveLocalizedRoute({ name: HOME }),
    title: i18n(translations.breadcrumbHome),
  },
  {
    link: resolveLocalizedRoute(priceGuidesHubLinkDefinition),
    title: i18n(translations.breadcrumbPriceGuidesHub),
  },
  {
    link: {},
    title: title.value,
  },
])

const isBestOffersTableOutOfStock = computed(() => {
  if (!bestOffersTable.value) {
    return true
  }

  return bestOffersTable.value.collections.every((row) =>
    row.every((cell) => cell === null),
  )
})

useHead(productModel, subtitle, { dateMonthLongCapitalized, dateYearNumeric })
</script>

export default {
  metaTitle: {
    id: 'price_guide_product_model_meta_title',
    defaultMessage:
      'How much does the {productModel} cost? | {dateMonthLongCapitalized} {dateYearNumeric}',
  },
  metaImageAlt: {
    id: 'price_guide_product_model_meta_image_alt',
    defaultMessage:
      '3 smartphones lined up: iPhone, Samsung Galaxy and Google Pixel',
  },
  metaArticleSection: {
    id: 'price_guide_product_model_meta_article_section',
    defaultMessage: 'Smartphones',
  },
} as const

import {
  toPublicPath,
  useAlternateLinks,
  useHead as useHeadBase,
  useRoute,
  useRuntimeConfig,
} from '#imports'
import { type Ref, computed } from 'vue'

import { MarketCountryCode } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import { useAddCanonicalLink } from '~/scopes/seo/composables/useAddCanonicalLink'

import translations from './useHead.translations'

export function useHead(
  productModel: Ref<string | undefined>,
  metaDescription: Ref<string>,
  {
    dateMonthLongCapitalized,
    dateYearNumeric,
  }: { dateMonthLongCapitalized: string; dateYearNumeric: string },
) {
  const { getAlternateLinks } = useAlternateLinks()
  const i18n = useI18n()
  const route = useRoute()
  const { KILL_PRICE_GUIDE_COUNTRIES } = useRuntimeConfig().public

  const metaTitle = computed(() =>
    i18n(translations.metaTitle, {
      dateMonthLongCapitalized,
      dateYearNumeric,
      productModel: productModel.value,
    }),
  )

  useHeadBase({
    title: metaTitle.value,
    meta: [
      {
        property: 'og:title',
        hid: 'og:title',
        content: metaTitle.value,
      },
      {
        name: 'description',
        hid: 'description',
        content: metaDescription.value,
      },
      {
        property: 'og:description',
        hid: 'og:description',
        content: metaDescription.value,
      },
      {
        name: 'og:image',
        hid: 'og:image',
        content: toPublicPath('/img/price-guide/og-image.jpg'),
      },
      {
        name: 'og:image:alt',
        hid: 'og:image:alt',
        content: i18n(translations.metaImageAlt),
      },
      {
        name: 'og:article:section',
        hid: 'og:article:section',
        content: i18n(translations.metaArticleSection),
      },
    ],
    link: getAlternateLinks({
      link: route.path,
      countryCodes: Object.values(MarketCountryCode).filter(
        (countryCode) => !KILL_PRICE_GUIDE_COUNTRIES.includes(countryCode),
      ),
    }),
  })
  useAddCanonicalLink()
}

import { createError } from '#imports'
import { type Ref } from 'vue'

import { type HttpError } from '@backmarket/http-api'

export function useHandleError(error: Ref<HttpError | null>) {
  if (!error.value) {
    return
  }

  throw createError({
    statusCode: error.value.statusCode,
    statusMessage: error.value.message,
    fatal: true,
  })
}
